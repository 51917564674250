module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TDRH5TQ","includeInDevelopment":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-28528570-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://1bfe66cedfd74749aa34fb66f5fec207:a45f4547ddb543b1997c16c8c8b1221a@sentry.io/1352056","config":{"environment":"production"}},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
